import * as React from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { CompanyInfoSteps } from "../../../model/AdminDTO";
import { setCompanyInfoStep } from "../../../redux/admin/admin-actions";
import ScreenWrapper from "../../AdminComponent/ScreenWrapper/ScreenWrapper";
import Button, { BUTTON_TYPE } from "../../Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import tick from "../../../assets/Icons/tick.svg";
import remove from "../../../assets/Icons/remove.svg";
import {getEsgCertificate, setApprovalCertificate} from "../../../redux/company/company-actions";
import {useEffect} from "react";
import {AppState} from "../../../redux/store";
import Table, { TableHeader } from '../../../components/Common/Table/Table';
import {EsgCertificateListInfoDTO, EsgCertificateApprovalDTO} from "../../../model/CompanyDTO";
import NoDataBox from "../../Common/NoDataBox/NoDataBox";
import pdf_icon from "../../../assets/Icons/pdf_icon.svg";
import {dwonloadEsgCertificate} from "../../../utils/commonUtils";
import {timeUtils} from "../../../utils/timeUtils";
import { Roles } from "../../../model/UserDTO";
import {Modal} from "react-bootstrap";



const EsgCertificateList: React.FC<any> = ({onPrevious, onConfirm, getEsgCertificate, esgCertificate, userRole, setApprovalCertificate}) => {
  const { t } = useTranslation();

  const [referenceIdCertificate, setReferenceIdCertificate] = React.useState<any>(null);
  const [approval, setApproval] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    getEsgCertificate();
  }, [getEsgCertificate]);

  const stylesActionHeaderTable = {
    companyName: {width: "10em"},
    certificateType: {width: "10em"},
    link: {cursor: "pointer", width: "10em"},
    classRating: {width: "10em"},
    scoreRating: {width: "10em"},
    emissionDate: {width: "10em"},
    expiryDate: {width: "10em"},
    approval: {width: "10em"},
    approvedDate: {width: "10em"},
    action: {width: "10em"},
  };

  const actionHeadersTable: TableHeader[] = [
    {
      label: t("supplier.esgCertificateList.companyName"),
      sortingAccessor: (a: EsgCertificateListInfoDTO) => a.companyName,
      style: stylesActionHeaderTable.companyName,
    },
    {
      label: t("supplier.esgCertificateList.certificateType"),
      sortingAccessor: (a: EsgCertificateListInfoDTO) => a.certificationType,
      style: stylesActionHeaderTable.certificateType,
    },
    {
      label: t("supplier.esgCertificateList.downloadCertificate"),
      style: stylesActionHeaderTable.link,
    },
    {
      label: t("supplier.esgCertificateList.classRating"),
      style: stylesActionHeaderTable.classRating,
    },
    {
      label: t("supplier.esgCertificateList.scoreRating"),
      style: stylesActionHeaderTable.scoreRating,
    },
    {
      label: t("supplier.esgCertificateList.emissionDate"),
      sortingAccessor: (a: EsgCertificateListInfoDTO) => a.emissionDate,
      style: stylesActionHeaderTable.emissionDate,
    },

    {
      label: t("supplier.esgCertificateList.expiryDate"),
      sortingAccessor: (a: EsgCertificateListInfoDTO) => a.expiryDate,
      style: stylesActionHeaderTable.expiryDate,
    },
    {
      label: t("supplier.esgCertificateList.approval"),
      style: stylesActionHeaderTable.approval,
    },
    {
      label: t("supplier.esgCertificateList.approvedDate"),
      sortingAccessor: (a: EsgCertificateListInfoDTO) => a.approvedDate,
      style: stylesActionHeaderTable.approvedDate,
    },
  ];

  if (userRole === Roles.ADMIN) {
    actionHeadersTable.push(
        {
          label: t("supplier.esgCertificateList.action"),
          style: stylesActionHeaderTable.action,
        }
    )
  }

  const onClickApproval = (id: number, approval: boolean) => {
    setReferenceIdCertificate(id);
    setApproval(approval);
    setShowModal(true);
  }

  const handleSubmitApproval = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      certificateId: referenceIdCertificate,
      approved: approval
    }

    await setApprovalCertificate(payload);
    await getEsgCertificate();
    setShowModal(false);
  }

  const renderRow = (item: EsgCertificateListInfoDTO, index: number) => {
    return (
        <div key={index}>
          <div className="actions__tableRow">
            <span className="table__item">{item.companyName}</span>
            <span className="table__item">{item.certificationType}</span>
            <span
                style={stylesActionHeaderTable.link}
                className="actions__pdfContainer table__item table__item--actions"
            >
                        <span className="actions__documentPDFContainer">
                            <img
                                onClick={() => dwonloadEsgCertificate(item.id)}
                                title={t('supplier.esgCertificateList.cardTitle')}
                                src={pdf_icon}
                                alt="pdf_icon"
                                className="actions__pdfImage"
                                height="30px"
                                key={index}
                            />

                        </span>
            </span>
            <span className="table__item">{(item.classRating) ? item.classRating : "-"}</span>
            <span className="table__item">{(item.scoreRating) ? item.scoreRating : "-"}</span>
            <span className="table__item">{timeUtils.formatToLocaleDate(item.emissionDate, "DD/MM/YY HH:mm")}</span>
            <span className="table__item">{timeUtils.formatToLocaleDate(item.expiryDate, "DD/MM/YY HH:mm")}</span>
            <span className="table__item">{(item.approved && item.approvedDate) ? t('supplier.esgCertificateList.approved') : t('supplier.esgCertificateList.notApproved')}</span>
            <span className="table__item">{(item.approvedDate) ? timeUtils.formatToLocaleDate(item.approvedDate, "DD/MM/YY HH:mm") : "-"}</span>
            {(userRole === Roles.ADMIN && !item.approvedDate) &&
                <span className="table__item">
                  <img
                      onClick={() => {onClickApproval(item.id, true)}}
                      src={tick}
                      alt="tick"
                      className="actions__pdfImage"
                      height="30px"
                      key={index}
                  />
                  <img
                      onClick={() => {onClickApproval(item.id, false)}}
                      src={remove}
                      alt="remove"
                      className="actions__pdfImage"
                      height="30px"
                      key={index}
                  />
                </span>
                }
          </div>
          <div
              className="table__line table__line--withMargin"
              style={{ opacity: 0.2 }}
          />
        </div>
    );
  };

  return (
    <ScreenWrapper title={t("supplier.esgCertificateList.cardTitle")}>
      <div>
        {!showModal ?
            <Table
              noItemsRender={esgCertificate?.length === 0 && <NoDataBox noDataTitle={t("ratingESG.noListCertificate")}/>}
              listToDisplay={esgCertificate}
              renderItem={renderRow}
              headers={actionHeadersTable}
              className="shadowCard actions__marginTop20"
              headerClassNameModifier="table__header--actions"
              classNameScrollContainer="availableCreditLine__tableScrollContainer"
            />
            :
            <Modal.Dialog>
              <Modal.Body>
                <div>
                  {(approval) ? t("supplier.esgCertificateList.modalQuestionConfirm") : t("supplier.esgCertificateList.modalQuestionReject")}
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button label={t('ratingESG.button.previous')} onClick={() => setShowModal(false)}/>
                <Button label={t("ratingESG.button.confirm")} onClick={handleSubmitApproval}/>
              </Modal.Footer>
            </Modal.Dialog>
        }
      </div>
      {(userRole !== Roles.ADMIN) ?
          <div className="containerButtons">
            <>
              <div className="containerButtonConfirm containerButtonConfirm--marginRight20">
                <Button
                    label={t("onBoarding.previous")}
                    onClick={onPrevious}
                    icon={forward_arrow_right}
                    buttonType={BUTTON_TYPE.primaryInverted}
                    iconOnTheLeft
                />
              </div>
              <div className="containerButtonConfirm">
                <Button
                    label={t("supplier.addEsgCertificate.uploadCertificate")}
                    onClick={onConfirm}
                    icon={forward_arrow_right}
                />
              </div>
            </>
          </div>
          :
          ""
      }
    </ScreenWrapper>
  );
};


const mapStateToProps = (store: AppState) => {
  return {
    esgCertificate: store.company.esgCertificate,
    userRole: store.auth.role,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getEsgCertificate:() => dispatch(getEsgCertificate()),

    setCompanyInfoStep: (payload: CompanyInfoSteps) =>
      dispatch(setCompanyInfoStep(payload)),

    setApprovalCertificate: (payload: EsgCertificateApprovalDTO) => dispatch(setApprovalCertificate(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EsgCertificateList);
